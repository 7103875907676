* {
  margin: 0;
  padding: 0;
  list-style: none;
}
img {
  width: 100%;
  display: block;
}
header {
  height: 68px;
  background: #458fff;
}
header > div {
  width: 100%;
  max-width: 1200px;
  height: 100%;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
}
header .logo {
  width: 365px;
}
header ul {
  display: flex;
  background: #458fff;
}
header li {
  padding: 0 20px;
  color: #fefefe;
}

header li.cur {
  color: #ffd457;
}

header li a {
  text-decoration: none;
  color: inherit;
}
.fold-btn {
  display: none;
}
@media screen and (max-width: 768px) {
  header {
    height: 60px;
  }
  header .logo {
    width: 264px;
    padding-left: 20px;
  }
  header ul {
    position: absolute;
    width: 100%;
    top: 60px;
    flex-wrap: wrap;
    padding-bottom: 50px;
    display: none;
  }
  header li {
    width: 100%;
    line-height: 60px;
    padding: 0 40px;
  }
  .fold-btn {
    display: block;
    width: 30px;
    margin-right: 20px;
  }
}
